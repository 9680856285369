.offers-con {
  width: 100%;
  height: 465px;
  background: transparent;
}
.offers-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.offers_content-header {
  position: absolute;
  bottom: 106px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.offers_title-birnchi {
  font-size: 72px;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
}

.offers {
  width: 100%;
  padding-top: 115px;
}

.offers_sorting {
  display: inline-block;
}
.offers_sorting > li {
  display: inline-block;
  position: relative;
  width: 166px;
  height: 50px;
  font-size: 12px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
  line-height: 50px;
  cursor: pointer;
  border: solid 2px #dadada;
  padding-left: 25px;
  padding-right: 14px;
  margin-right: 16px;
}
.distance_item {
  width: 234px !important;
}
.offers_sorting li i {
  float: right;
  line-height: 48px;
  color: #929191;
}
.offers_sorting li ul {
  display: block;
  position: absolute;
  right: 0;
  top: 120%;
  margin: 0;
  width: 100%;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 15px 25px rgba(63, 78, 100, 0.15);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}
.offers_sorting > li:hover ul {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 1px);
}
.offers_sorting li ul li {
  display: block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}
.offers_sorting li ul li span {
  display: block;
  height: 40px;
  line-height: 40px;
  border-bottom: solid 1px #dddddd;
  color: #51545f;
  text-align: left;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: all 0.3s ease;
}
.offers_sorting li ul li span:hover {
  color: #b5aec4;
}
.offers_sorting li ul li:last-child span {
  border-bottom: none;
}
.offers_sorting li span:nth-child(2) {
  margin-left: 71px;
}

.offers_grid {
  width: 100%;
  margin-top: 66px;
}
.offers_item {
  width: 100%;
  margin-bottom: 46px;
}
.offers_item:last-child {
  margin-bottom: 29px;
}
.offers_image img {
  width: 100%;
}
.offers_image_container {
  width: 100%;
  height: 100%;
}
.offers_image_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.offer_name {
  position: absolute;
  left: 0;
  bottom: 27px;
  width: 180px;
  height: 33px;
  background: #fa9e1b;
  overflow: hidden;
}
.offer_name a {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 35px;
  padding-left: 22px;
}
.offers_content {
}
.offers_price {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  color: #eda84a;
  line-height: 25px;
}
.offers_price span {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  margin-left: 12px;
}
.offers_rating {
}
.offers_rating i::before {
  font-size: 13px;
  margin-right: 2px;
}
.offers_text {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  margin-top: 16px;
  margin-bottom: 0px;
}
.info {
  width: 400px;
}

.offers_icons {
  margin-top: 21px;
}
.offers_icons_item {
  display: inline-block;
  margin-right: 15px;
}
.offers_icons_item:last-child {
  margin-right: 0px;
}
.book_button {
  background: #fa9e1b;
  margin-top: -39px;
  margin-bottom: -5px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  border-radius: 0;
}
.book_button:hover {
  opacity: 0.8;
}
.offer_reviews {
  position: absolute;
  top: 0;
  right: 0;
}
.offer_reviews_content {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.offer_reviews_title {
  font-size: 12px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
}
.offer_reviews_subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #929191;
  margin-top: 1px;
}
.offer_reviews_rating {
  display: inline-block;
  width: 62px;
  height: 57px;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  line-height: 57px;
  background: #fa9e1b;
  vertical-align: middle;
  border-radius: 45%;
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}
