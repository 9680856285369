.about {
  width: 100%;
  height: 465px;
  background: transparent;
}
.about_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.about_content-header {
  position: absolute;
  bottom: 106px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.about_title {
  font-size: 72px;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
}

.intro {
  width: 100%;
  padding-top: 92px;
  padding-bottom: 105px;
  background: #f6f9fb;
}
.intro_image {
  position: absolute;
  right: 0;
  top: 13px;
}
.intro_title {
  font-size: 30px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
  text-align: center;
}
.intro_text {
  margin-bottom: 0px;
  font-weight: 600;
  color: #929191;
  margin-top: 30px;
  text-align: justify;
}
.info-button {
  margin-top: 30px;
  z-index: 1;
  margin-left: 120px;
}

.section_title {
  font-size: 30px;
  font-weight: 700;
  color: #2d2c2c;
  margin-top: -8px;
  text-transform: uppercase;
}

.rating {
  margin-top: 10px;
}
.rating i {
  font-size: 19px;
  margin-right: 4px;
}
.rating i:last-child {
  margin-right: 0px;
}
.rating_1 i:first-child {
  color: #ffeb8d;
}
.rating_2 i:first-child {
  color: #ffeb8d;
}
.rating_2 i:nth-child(2) {
  color: #fed46b;
}
.rating_3 i:first-child {
  color: #ffeb8d;
}
.rating_3 i:nth-child(2) {
  color: #fed46b;
}
.rating_3 i:nth-child(3) {
  color: #fbb53d;
}
.rating_4 i:first-child {
  color: #ffeb8d;
}
.rating_4 i:nth-child(2) {
  color: #fed46b;
}
.rating_4 i:nth-child(3) {
  color: #fbb53d;
}
.rating_4 i:nth-child(4) {
  color: #fa9e1b;
}
.rating_5 i:first-child {
  color: #ffeb8d;
}
.rating_5 i:nth-child(2) {
  color: #fed46b;
}
.rating_5 i:nth-child(3) {
  color: #fbb53d;
}
.rating_5 i:nth-child(4) {
  color: #fa9e1b;
}
.rating_r i::before {
  font-family: "FontAwesome";
  content: "\f006";
  font-style: normal;
  font-size: 19px;
  margin-right: 4px;
}
.rating_r_1 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_2 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_2 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_3 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_3 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_3 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_4 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_4 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_4 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_4 i:nth-child(4)::before {
  color: #fa9e1b;
  content: "\f005";
}
.rating_r_5 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_5 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_5 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_5 i:nth-child(4)::before {
  color: #fa9e1b;
  content: "\f005";
}
.rating_r_5 i:nth-child(5)::before {
  color: #ef910a;
  content: "\f005";
}

.stats {
  padding-top: 100px;
  padding-bottom: 105px;
}
.stats_text {
  margin-top: 21px;
  padding-left: 30px;
  padding-right: 30px;
}
.stats_years {
  padding-left: 116px;
  padding-right: 116px;
  margin-top: 39px;
}
.stats_contents {
  margin-top: 23px;
  padding-left: 55px;
  padding-right: 55px;
}
.stats_years div {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fa9e1b;
}
.stats_item {
  margin-bottom: 24px;
}
.stats_item:last-child {
  margin-bottom: 0px;
}
.stats_last {
  width: 25%;
  float: left;
  padding-left: 60px;
}
.stats_new {
  width: 25%;
  float: left;
  padding-right: 60px;
}
.stats_last_icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 100%;
  padding-bottom: 5px;
}
.stats_new_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 100%;
  padding-bottom: 5px;
}

.stats_number {
  font-size: 24px;
  font-weight: 600;
  color: #2d2c2c;
}
.stats_type {
  font-size: 14px;
  font-weight: 600;
  color: #929191;
}
.stats_bar {
  width: 50%;
  height: 2px;
  background: #e1e1e1;
  float: left;
  margin-top: 33px;
}
.stats_bar_perc {
  position: absolute;
  top: 0;
  height: 2px;
  background: #31124b;
  max-width: 100% !important;
}
.stats_bar_perc div {
  width: 100%;
}
.stats_bar_value {
  position: absolute;
  top: -39px;
}

.add {
  width: 100%;
}
.add_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.add_container {
  padding-top: 52px;
  padding-bottom: 64px;
}
.add_content {
  padding-left: 151px;
}
.add_title {
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 2px;
}
.add_subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding-left: 7px;
}
.add_subtitle span {
  font-size: 18px;
  font-weight: 700;
}
.add_button {
  margin-top: 30px;
}

.milestones {
  padding-top: 105px;
  padding-bottom: 100px;
}
.milestone {
  width: 100%;
}
.milestone_icon {
  display: inline-block;
  height: 58px;
}
.milestone_icon img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.milestone_counter {
  font-size: 48px;
  font-weight: 600;
  color: #2d2c2c;
  line-height: 1;
  margin-top: 25px;
}
.milestone_text {
  font-size: 14px;
  font-weight: 600;
  color: #9a9999;
  margin-top: 22px;
  text-transform: uppercase;
}
