.news {
  width: 100%;
  height: 465px;
  background: transparent;
}
.news_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.news_content {
  position: absolute;
  bottom: 106px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.news_title {
  font-size: 72px;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
}

.blog {
  padding-top: 108px;
  padding-bottom: 108px;
}
.blog_post_container {
  width: 100%;
}
.blog_post:not(:last-child) {
  margin-bottom: 74px;
}

.blog_post_image img {
  width: 100%;
}
.blog_post_date {
  position: absolute;
  top: 30px;
  left: 27px;
  width: 114px;
  height: 74px;
  border-radius: 37px;
  background: #fa9e1b;
}
.blog_post_day {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1;
}
.blog_post_month {
  font-size: 12px;
  color: #ffffff;
  margin-top: 3px;
}
.blog_post_meta {
  margin-top: 32px;
}
.blog_post_meta_item {
  display: inline-block;
  position: relative;
  margin-right: 27px;
}
.blog_post_meta_item:last-child {
  margin-right: 0px;
}
.blog_post_meta_item::after {
  display: block;
  position: absolute;
  top: 7px;
  right: -15px;
  width: 1px;
  height: 12px;
  background: #929191;
  content: "";
}
.blog_post_meta_item:last-child::after {
  display: none;
}
.blog_post_meta_item a {
  font-size: 12px;
  font-weight: 600;
  color: #929191;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_post_meta_item a:hover {
  color: #2b2b2b;
}
.blog_post_title {
  margin-top: 5px;
}
.blog_post_title a {
  font-size: 24px;
  font-weight: 600;
  color: #2b2b2b;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_post_title a:hover {
  color: #fa9e1b;
}
.blog_post_text {
  margin-top: 19px;
}
.blog_post_text p {
  color: #929191;
  line-height: 2.14;
  text-align: justify;
}
.blog_post_info {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
.blog_post_text p:last-of-type {
  margin-bottom: 0px;
}
.blog_post_link {
  margin-top: 32px;
}
.blog_post_link a {
  font-size: 14px;
  font-weight: 700;
  color: #fa9e1b;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_post_link a:hover {
  color: #361f5d;
}
.blog_navigation {
  margin-top: 100px;
}
.blog_dot {
  display: inline-block;
  position: relative;
  height: 35px;
  line-height: 35px;
  color: #2b2b2b;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_dot:hover {
  color: #fa9e1c;
}
.blog_dot:hover.active div {
  border-color: #fa9e1c;
}
.blog_dot.active {
  padding-left: 13px;
  padding-right: 9px;
  margin-right: 3px;
  color: #fa9e1c;
}
.blog_dot div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px transparent;
  border-radius: 18px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.blog_dot.active div {
  border: solid 2px #fa9e1c;
}


.sidebar_search {
  width: 100%;
}
.sidebar_search_input {
  width: 100%;
  height: 44px;
  border: none;
  border-bottom: solid 2px #e6e7f4;
  outline: none;
}
.sidebar_search_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  height: 44px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.sidebar_search_button .mag_glass {
  fill: #fa9e1b;
}
.sidebar_search_button:hover .mag_glass {
  fill: #371d5a;
}
.sidebar_archives {
  margin-top: 94px;
}
.sidebar_title {
  font-size: 18px;
  font-weight: 600;
  color: #404551;
}
.sidebar_list {
  margin-top: 40px;
}
.sidebar_list li:not(:last-child) {
  margin-bottom: 19px;
}
.sidebar_list li a {
  font-size: 14px;
  font-weight: 600;
  color: #929191;
}
.sidebar_list li a:hover {
  color: #404551;
}
.sidebar_categories {
  margin-top: 89px;
}
.sidebar_latest_posts {
  margin-top: 160px;
}
.latest_posts_container {
  margin-top: 35px;
}
.latest_post:not(:last-child) {
  margin-bottom: 23px;
}
.latest_post_image {
  float: left;
  z-index: 2;
}
.latest_post_content {
  padding-left: 98px;
}
.latest_post_title a {
  font-size: 15px;
  font-weight: 600;
  color: #2b2b2b;
}
.latest_post_title a:hover {
  color: #fa9e1b;
}
.latest_post_meta {
  margin-top: 3px;
}
.latest_post_author {
  display: inline-block;
  margin-right: 8px;
}
.latest_post_author a {
  font-size: 12px;
  font-weight: 600;
  color: #929191;
}
.latest_post_author a:hover {
  color: #2b2b2b;
}
.latest_post_author::after {
  display: block;
  position: absolute;
  top: 1px;
  right: -8px;
  content: "/";
}
.latest_post_date {
  display: inline-block;
}
.latest_post_date a {
  font-size: 12px;
  font-weight: 600;
  color: #929191;
}
.latest_post_date a:hover {
  color: #2b2b2b;
}
.sidebar_gallery {
  margin-top: 94px;
}
.gallery_container {
  margin-top: 42px;
}
.gallery_item {
  width: calc((100% - 18px) / 3);
  margin-bottom: 9px;
}
.gallery_item img {
  width: 100%;
}
