* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  background-color: #f6f6f6 !important;
  font-weight: 400;
}
div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 1px #ffa07f;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
p a:active {
  position: relative;
  color: #ff6347;
}
p a:hover {
  color: #ffffff;
  background: #ffa07f;
}
p a:hover::after {
  opacity: 0.2;
}
::selection {
}
p::selection {
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
.form-control {
  color: #db5246;
}
section {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.clear {
  clear: both;
}
.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}
.clearfix::after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.trans_200 {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.trans_300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.trans_400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.trans_500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.fill_height {
  height: 100%;
}
.super_container {
  width: 100%;
  overflow: hidden;
}
.prlx_parent {
  overflow: hidden;
}
.prlx {
  height: 130% !important;
}
.nopadding {
  padding: 0px !important;
}
.button {
  display: inline-block;
  height: 53px;
  border-radius: 27px;
  overflow: hidden;
  z-index: 1;
}
.button_bcg {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, #fa9e1b, #8d4fff, #fa9e1b);
  z-index: -1;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.button:hover .button_bcg {
  left: -100%;
}
.button a {
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 46px;
  padding-right: 46px;
  white-space: nowrap;
  z-index: 2;
}
.button span {
  display: inline-block;
  vertical-align: middle;
  width: 5.75px;
  height: 5.63px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 2px;
}
.button span:first-child {
  margin-left: 10px;
}
.button span:nth-child(2) {
  opacity: 0.6;
}
.button span:last-child {
  opacity: 0.4;
  margin-right: 0px;
}
.parallax-window {
  min-height: 400px;
  background: transparent;
}

.dropdown-menu {
  min-width: 5.5rem !important;
}

@media only screen and (max-width: 600px) {
  .header {
    .navbar {
      background-color: #fff;
      height: auto;

      .logo a img {
        height: 62px;
      }
      .main_nav_item a {
        color: #000;
      }
      .globus {
        color: #000;
        margin-bottom: 10px;
      }
    }
  }
  .detailed-content {
    margin-top: -118px !important;
  }
  .detailed_title-birnchi {
    h1 {
      font-size: 30px;
    }
    h3 {
      font-size: 19px;
      width: 300px;
    }
  }
  .nav-tabs {
    margin-left: 47px !important;
    display: block;
    li {
      margin-bottom: 15px !important;
      background-color: #346a89 !important;
      margin-right: 50rem !important;
      button {
        width: 300px;
      }
    }
  }
  .tours-tabs__info {
    display: block !important;
    div {
      display: block !important;

      .bir-balo {
        margin-left: 48px !important;
        margin-top: -39px;
      }
    }
  }
  .tours-tabs__content {
    width: 40%;
    padding-left: 10px !important;
    padding-right: 50px;
  }
  .detailed-content {
    .order-content {
      margin-left: auto !important;
      .price-decoration {
        margin-left: 8%;
      }
    }
  }
  .ReactModal__Content {
    width: 100% !important;
  }
  .close-btn {
    margin-left: 49px;
  }
  .your-mail {
    margin-top: 7px;
  }
}
.text-center {
  margin-bottom: 38px;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbar {
    padding-left: 80px;
    padding-right: 80px;

    .logo {
      margin-top: -15px;
    }
    .navbar-nav {
      margin-top: 10px;
      margin-left: 200px;
      .dropdown {
        margin-top: -15px;
      }
    }
    .globus {
      color: #fff;
      margin-bottom: 10px;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
