@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@100;200;300;400;500;600;700&display=swap);

body {
  font-family: "PT Sans", sans-serif;
  background-color: #fff;
}
.detailed-con {
  width: 100%;
  height: 620px;
  background: transparent;
  .detailed-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .detailed_content-header {
      position: absolute;
      bottom: 250px;
      left: 50%;
      text-align: center;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .detailed_title-birnchi {
      // font-size: 72px  ;
      font-weight: 800;
      color: #ffffff;
      // text-transform: uppercase;
      h1 {
      }
    }
  }
}

.detailed-content {
  padding: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 1100px;
  position: relative;

  margin-top: -170px;
  .tours-tabs {
    // margin-top: -170px;
    .nav-tabs {
      margin: 0;
      border-bottom: 3px solid #ff0202;

      li {
        background-color: #fff;
        margin: 0 7px 0 0;
        border: none;
        border-bottom: none;
        padding: 0;
        position: inherit;
        button {
          border-radius: 3px;
          border: none;
          background-color: #fff;
          height: 48px;
          line-height: 50px;
          padding: 0 20px;
          outline: none;
          cursor: pointer;
          position: relative;
          display: block;
          font-weight: bold;
        }
      }
    }
    .active {
      color: #fff;
      background-color: #ff0202 !important;
    }
  }

  .tab-content {
    .tours-tabs__info {
      display: table;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      background: #fdfdfd;

      .tours-tabs__info__item {
        display: table-cell;
        height: 120px;
        padding: 20px;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #ebebeb;

        .tours-tabs__info__item__content {
          display: inline-block;
          padding-left: 50px;
          position: relative;
          text-align: left;

          .tours-tabs__info__item__icon {
            svg {
              width: 30px !important;
              font-size: 30px;
              color: #ff0202;
            }
          }
          .tours-tabs__info__item__title {
            font-weight: 700;
            line-height: 1.5em;
            color: #000;
            font-size: 15px;
          }
          .tours-tabs__info__item__description {
            font-size: 0.933em;
            color: #a1a1a1;
          }
        }
      }
    }
    .padding-all {
      padding-top: 40px;
      padding-right: 50px;
      padding-bottom: 40px;
      padding-left: 50px;
    }
    .tours-tabs__content {
      background-color: #fff;
      color: #000;
      h2 {
        margin: 0;
        margin-bottom: 30px;
        font-size: 2em;
      }
      p {
        margin: 0;
        margin-bottom: 15px;
        color: #000;
      }
      .tours-tabs__table {
        width: 100% !important;
        height: auto !important;
        margin: 30px 0;
        border: none;
        border-top: 2px solid #e4e4e4;
        border-bottom: 2px solid #e4e4e4;
        font-size: 0.933em;
        tbody {
          tr {
            border-bottom: 1px solid #e4e4e4;
            td {
              padding: 20px;
              vertical-align: top;
              border: none;
            }
          }
        }
      }
      .included-ul-content {
        li {
          // list-style-image: url(../../../assets/Images/TRAVELSYSTEM.png);
          img {
            padding: 5px;
          }
          font-size: 1.1em;
          &::marker {
            unicode-bidi: isolate;
            font-variant-numeric: tabular-nums;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
          }
        }
      }
      .extra {
        li {
          img {
            padding: 5px;
          }
        }
      }

      .timeline {
        .timeline__item {
          display: table;
          width: 100%;
          margin-bottom: 2px;

          .timeline__item__icon-wrap {
            display: table-cell;
            vertical-align: top;
            width: 70px;
            position: relative;
            overflow: hidden;

            &::before {
              content: " ";
              display: block;
              border-left: 4px dotted #ff0202;
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              -moz-transform: translate(-50%, 0);
              -ms-transform: translate(-50%, 0);
              -webkit-transform: translate(-50%, 0);
              -o-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
              z-index: 1;
            }
            .timeline__item__icon {
              display: inline-block;
              width: 70px;
              height: 70px;
              line-height: 70px;
              background: #ff0202;
              border-radius: 100%;
              text-align: center;
              font-family: "PT Sans";
              font-size: 1.467em;
              color: #fff;
              position: relative;
              z-index: 2;

              &:before {
                content: " ";
                display: block;
                width: 60px;
                height: 60px;
                background: transparent;
                position: absolute;
                top: 5px;
                left: 5px;
                border-radius: 100%;
                border: 3px solid rgba(255, 255, 255, 0.5);
              }
              .timeline__item__icon__text {
                display: inline-block;
                margin-left: -3px;
              }
            }
          }
          .timeline__item__content {
            display: table-cell;
            vertical-align: top;
            padding-bottom: 10px;

            .timeline__item__title {
              margin: 20px 0 !important;
              font-family: PT Sans;
              font-weight: 700;
              font-style: normal;
              font-size: 1.467em;
            }
            .timeline__item__description {
              p {
                font-size: 15px;
                color: #333333;
              }
            }
          }
          .padding-left {
            padding-left: 50px;
          }
        }
      }

      .product-thumbnails {
        .product-item-foto {
          // gap: 15px;
          margin-top: -20px;
          div {
            margin-top: 20px;
            float: none;
            display: inline-block;
            text-align: center;

            span {
              img {
                width: 500px;
                height: 230px;
              }
            }
          }
        }
      }
    }
  }

  .order-container {
    .order-content {
      margin-left: 20%;
      .btn-order {
        cursor: pointer;
        margin-left: 10.5%;
        display: inline-block;
        line-height: 3em;
        padding: 0 2em;
        border: none;
        border-radius: 3px;
        white-space: nowrap;
        vertical-align: top;
        text-transform: uppercase;
        color: #fff;
        font-size: 15px;
        font-family: PT Sans;
        font-weight: 700;
        font-style: normal;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        background: rgba(255, 2, 2, 0.8);
        border-radius: 50px;
        outline: none;
        &:hover {
          background-color: #ff0202;
          color: #fff;
        }

        .order-icon {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 0;
          margin-left: 10px;

          &:before {
            content: "\f178";
          }
        }
      }
      .price-decoration {
        text-align: center;
        color: #fff;
        position: relative;
        .price-description-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          padding: 5px 20px;
          background: #ff0202;
          -webkit-border-top-left-radius: 5px;
          border-top-left-radius: 5px;
          -webkit-border-top-right-radius: 3px;
          border-top-right-radius: 3px;
          -webkit-border-bottom-right-radius: 0;
          border-bottom-right-radius: 0;
          -webkit-border-bottom-left-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 1.6em;
          font-family: PT Sans;
          font-weight: 700;
          font-style: normal;
          width: 248px;
        }
        .price-decoration__label {
          padding: 10px 20px;
          -webkit-border-top-left-radius: 0;
          border-top-left-radius: 0;
          -webkit-border-top-right-radius: 0;
          border-top-right-radius: 0;
          -webkit-border-bottom-right-radius: 3px;
          border-bottom-right-radius: 3px;
          -webkit-border-bottom-left-radius: 3px;
          border-bottom-left-radius: 3px;
          font-family: PT Sans;
          font-weight: 700;
          font-style: normal;
          background: rgba(255, 255, 255, 90%);
          color: #000;
          width: 248px;
        }
      }
    }
  }
}

.modal-contain {
  .form-contact__fields-short {
    input {
      padding-left: 11px;
      padding-right: 0px;
      -webkit-transition: all 0.1s ease-in-out;
      -o-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
      text-align: left;
      width: 100%;
      height: 45px;
      // border: 1px solid #ffff;
      margin: 0 0 10px;
      color: #7d7777;
      outline: none;
    }
    .form-contact__item-short {
      display: inline-block;
      width: 50% !important;
      padding-right: 5px;
      position: relative;
      &:nth-last-child(2) {
        float: right;
      }
    }
  }
  .atbtn {
    display: inline-block;
    line-height: 3em;
    padding: 0 2em;
    border: none;
    border-radius: 3px;
    white-space: nowrap;
    vertical-align: top;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    font-family: PT Sans;
    font-weight: 700;
    font-style: normal;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background: #ff0202;
    margin-left: 17% !important ;
    margin-top: 42px !important;

    .modal-button {
      margin: 0;
      color: #fff;
      border: 0px;
      // padding: 0 2em 0 55px;
      border: none;
      outline: 0;
      background: #ff0202;
      text-transform: uppercase;
    }
  }
}

.close-btn {
  margin-top: -5px;
  margin-left: 25px;
}
