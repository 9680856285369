.contact {
  width: 100%;
  height: 465px;
  background: transparent;
}
.contact_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.contact_content {
  position: absolute;
  bottom: 106px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.contact_title {
  font-size: 72px;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
}
.contact-text {
  color: red;
}

.contact_form_section {
  padding-top: 105px;
}
.contact_form_container {
  padding-top: 74px;
  padding-left: 98px;
  padding-right: 98px;
  padding-bottom: 88px;
  background: linear-gradient(to top right, #fa9e1b, #8d4fff);
}
.contact_title {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}
.contact_form {
  margin-top: 52px;
  font-size: 0px;
}
.input_field {
  height: 48px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
  background: transparent;
  color: #ffffff;
  border-bottom: solid 2px #e1e1e1;
}
.contact_form_name {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.contact_form_email {
  width: calc((100% - 30px) / 2);
}
.contact_form_subject {
  width: 100%;
  margin-top: 11px;
}
.contact_form_message {
  height: 126px;
  width: 100%;
  border: none;
  outline: none;
  margin-top: 19px;
  background: transparent;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  border-bottom: solid 2px #e1e1e1;
  padding-top: 11px;
}
.form_submit_button {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 29px;
  padding-right: 29px;
  background: #31124b;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  border: none;
  outline: none;
  margin-top: 37px;
  cursor: pointer;
}
.form_submit_button:hover {
  opacity: 0.8;
}
.input_field::-webkit-input-placeholder,
.text_field::-webkit-input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field:-moz-placeholder,
.text_field:-moz-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field::-moz-placeholder,
.text_field::-moz-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field:-ms-input-placeholder,
.text_field:-ms-input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field::input-placeholder,
.text_field::input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.about {
  padding-top: 55px;
  padding-bottom: 146px;
  z-index: 1;
}
.about_image {
  position: absolute;
  top: -38px;
  left: 15px;
}
.about_content {
  padding-top: 50px;
}
.about_logo {
  position: absolute;
  left: 0;
  top: 4px;
}
.about_logo a {
  color: #2e2e2e;
  font-weight: 800;
  text-transform: uppercase;
}
.about_text {
  font-size: 14px;
  font-weight: 600;
  line-height: 2.29;
  color: #929191;
  text-align: justify;
}
.about_social_list {
  margin-top: 40px;
}
.about_social_item {
  display: inline-block;
  width: 47px;
  height: 47px;
  border: solid 2px #fa9e1b;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  margin-right: 15px;
}
.about_social_item:last-child {
  margin-right: 0px;
}
.about_social_item a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.about_social_item a i {
  display: block;
  position: relative;
  color: #fa9e1b;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.about_social_item:hover {
  background: #fa9e1b;
}
.about_social_item:hover a i {
  color: #ffffff;
}
.about_info {
  padding-top: 100px;
}
.about_info .contact_info_item:last-child {
  margin-bottom: 0px;
}
.about_info .contact_info_list {
  margin-top: 0px;
}
.about_info .contact_info_text,
.about_info .contact_info_text a {
  color: #2e2e2e;
}

.travelix_map {
  width: 100%;
}
.google_map {
  width: 100%;
  height: 581px;
}
.map_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#map {
  width: 100%;
  height: calc(100% + 30px);
  margin-top: 120px;
}
