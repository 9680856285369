@charset "utf-8";


* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
}
div {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.footer_social_list {
  list-style: none;
  margin-bottom: 0px;
  margin-top: 180px;
}

ul {
  list-style: none;
  margin-bottom: 0px;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
  color: #929191;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
p a {
  display: inline;
  position: relative;
  color: inherit;
  border-bottom: solid 1px #ffa07f;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
p a:active {
  position: relative;
  color: #ff6347;
}
p a:hover {
  color: #ffffff;
  background: #ffa07f;
}
p a:hover::after {
  opacity: 0.2;
}

h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.form-control {
  color: #db5246;
}
section {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.clear {
  clear: both;
}
.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}
.clearfix::after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.trans_200 {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.trans_300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.trans_400 {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.trans_500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.fill_height {
  height: 100%;
}
.super_container {
  width: 100%;
  overflow: hidden;
}
.prlx_parent {
  overflow: hidden;
}
.prlx {
  height: 130% !important;
}
.nopadding {
  padding: 0px !important;
}
.button {
  display: inline-block;
  height: 53px;
  border-radius: 27px;
  overflow: hidden;
}
.button_bcg {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, #fa9e1b, #8d4fff, #fa9e1b);
  z-index: -1;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.button:hover .button_bcg {
  left: -100%;
}
.button a {
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 46px;
  padding-right: 46px;
  white-space: nowrap;
  z-index: 2;
}
.button span {
  display: inline-block;
  vertical-align: middle;
  width: 5.75px;
  height: 5.63px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 2px;
}
.button span:first-child {
  margin-left: 10px;
}
.button span:nth-child(2) {
  opacity: 0.6;
}
.button span:last-child {
  opacity: 0.4;
  margin-right: 0px;
}



.header {
  position: fixed;
  width: 100%;
  background: rgba(54, 19, 84, 0.6);
  z-index: 12;
}
.header.scrolled {
  background: rgba(54, 19, 84, 0.85);
}

.top_bar {
  width: 100%;
  height: 36px;
  background: #350a4e;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.header.scrolled .top_bar {
  visibility: hidden;
  opacity: 0;
  height: 0px;
}
.phone {
  display: inline-block;
  margin-right: 36px;
  line-height: 36px;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
}
.social_list_item {
  position: relative;
  display: inline-block;
  margin-right: 3px;
}
.social_list_item:last-child {
  margin-right: 0px;
}
.social_list_item a {
  display: block;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 36px;
}
.social_list_item:hover a i {
  color: #fa9e1c;
}
.social_list_item a i {
  font-size: 12px;
  color: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.social_list_item a:hover {
  color: #ffffff;
}
.user_box_link {
  display: inline-block;
}
.user_box_link a {
  display: block;
  font-size: 10px;
  line-height: 36px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}
.user_box_link a:hover {
  color: #fa9e1c;
}
.user_box_login {
  margin-right: 15px;
}
.user_box_login::after {
  display: block;
  position: absolute;
  top: 15px;
  right: -9px;
  background: #ffffff;
  width: 1px;
  height: 9px;
  content: "";
}
.main_nav_col {
  height: 100px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.header.scrolled .main_nav_col {
  height: 100px;
}


.logo_container {
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  transform: translateY(-6px);
}
.header.scrolled .logo_container {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.logo a {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  color: #f4f4f8;
  font-weight: 800;
  text-transform: uppercase;
}
.logo a img {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 9px;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
  transform: translateY(2px);
}

.main_nav_container {
  margin-right: 221px;
}
.main_nav_item {
  position: relative;
  display: inline-block;
  margin-right: 40px;
}
.main_nav_item:last-child {
  margin-right: 0px;
}
.main_nav_item a {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-top: 10px;
}
.main_nav_item::after {
  display: block;
  position: absolute;
  left: -1px;
  bottom: -11px;
  width: calc(100% + 2px);
  height: 2px;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
  content: "";
  opacity: 0;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.main_nav_item:hover::after {
  opacity: 1;
}
.content_search {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
  cursor: pointer;
}
.header.scrolled .content_search {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.mag_glass {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.content_search img {
  width: 17px;
  height: 17px;
}
.content_search:hover .mag_glass {
  fill: #fa9e1c;
}
.search_form {
  position: absolute;
  right: 15px;
  top: 120px;
  width: 237px;
  height: 40px;
  visibility: hidden;
  opacity: 0;
}
.search_form.active {
  top: 105px;
  visibility: visible;
  opacity: 1;
}
.search_content_input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #1b0b32;
  padding-left: 20px;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}
.bez_1 {
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}


.hamburger {
  display: none;
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
  cursor: pointer;
  margin-left: 20px;
}
.header.scrolled .hamburger {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.hamburger i {
  font-size: 24px;
  color: #ffffff;
}
.hamburger:hover i {
  color: #fa9e1c;
}
.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
}
.menu.active {
  opacity: 0.95;
  visibility: visible;
}
.menu_content {
  width: 100%;
  height: 100%;
}
.menu_item {
  position: relative;
  margin-bottom: 3px;
}
.menu_item:last-child {
  margin-bottom: 0px;
}
.menu_logo {
  margin-bottom: 28px;
}
.menu_logo a img {
  width: 40px !important;
}
.menu_item a {
  display: inline-block;
  position: relative;
  font-family: "Beyond";
  font-size: 36px;
  color: #ffffff;
  font-weight: 400;
}
.menu_item a::after {
  display: block;
  position: absolute;
  top: 60%;
  left: 0;
  width: 0;
  height: 4px;
  background: #ffffff;
  letter-spacing: 2px;
  content: "";
  z-index: 10;
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 400ms ease;
}
.menu_item:hover a::after {
  width: 100%;
}
.menu_close_container {
  position: absolute;
  top: 94px;
  right: 122px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu_close {
  top: 9px;
  width: 21px;
  height: 3px;
  background: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.menu_close::after {
  display: block;
  position: absolute;
  top: -9px;
  left: 9px;
  content: "";
  width: 3px;
  height: 21px;
  background: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.menu_close_container:hover .menu_close,
.menu_close_container:hover .menu_close::after {
  background: #fa9e1c;
}


.home {
  width: 100%;
  height: 100vh;
}


.home_slider_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #31124b;
}
.home_slider {
  width: 100%;
  height: 100%;
}
.home_slider_item {
  width: 100%;
  height: 100%;
}
.home_slider_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
}
.home_slider_content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
}
.home_slider_content h1 {
  margin-bottom: 0px;
}
.home_slider_content h1:first-child {
  font-size: 112px;
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
}
.home_slider_content h1:nth-child(2) {
  font-family: "Beyond";
  font-size: 100px;
  color: #ffffff;
  margin-top: -34px;
}
.home_slider_button {
  margin-top: 70px;
}
.flipInX {
  animation-delay: 600ms;
}


.home_slider_nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 11;
  cursor: pointer;
}
.home_slider_prev {
  left: 50px;
}
.home_slider_next {
  right: 50px;
}
.home_slider_prev:hover .nav_path,
.home_slider_prev:hover .nav_arrow {
  fill: url(#home_grad_prev);
}
.home_slider_next:hover .nav_arrow,
.home_slider_next:hover .nav_path {
  fill: url(#home_grad_next);
}

.home_slider_dots {
  position: absolute;
  right: 50px;
  bottom: 45px;
  z-index: 10;
}

.home_slider_custom_dot {
  display: inline-block;
  position: relative;
  height: 35px;
  line-height: 35px;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.home_slider_custom_dot:hover {
  color: #fa9e1c;
}
.home_slider_custom_dot:hover.active div {
  border-color: #fa9e1c;
}
.home_slider_custom_dot.active {
  padding-left: 13px;
  padding-right: 9px;
  margin-right: 3px;
}
.home_slider_custom_dot div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px transparent;
  border-radius: 18px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.home_slider_custom_dot.active div {
  border: solid 2px #ffffff;
}


.search {
  width: 100%;
  z-index: 10;
}
.search_tabs_container {
  position: absolute;
  bottom: 100%;
  left: 15px;
  width: calc(100% - 30px);
}
.search_tabs {
  height: 94px;
}
.search_tab {
  font-size: 14px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
  text-align: center;
  height: 100%;
  flex-grow: 1;
  background: #ffffff;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.search_tab img {
  margin-right: 10px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.search_tab:first-child {
  border-top-left-radius: 94px;
  padding-left: 7px;
}
.search_tab:last-child {
  border-top-right-radius: 94px;
  padding-right: 7px;
}
.search_tab.active {
  background: #fa9e1b;
  color: #ffffff;
}
.search_tab.active img {
  -webkit-filter: brightness(200%) grayscale(100%);
  -moz-filter: brightness(200%) grayscale(100%);
  -ms-filter: brightness(200%) grayscale(100%);
  -o-filter: brightness(200%) grayscale(100%);
  filter: brightness(200%) grayscale(100%);
}
.search_tab:hover {
  background: #fa9e1b;
  color: #ffffff;
}
.search_tab:hover img {
  -webkit-filter: brightness(200%) grayscale(100%);
  -moz-filter: brightness(200%) grayscale(100%);
  -ms-filter: brightness(200%) grayscale(100%);
  -o-filter: brightness(200%) grayscale(100%);
  filter: brightness(200%) grayscale(100%);
}
.search_panel {
  display: none !important;
  width: 100%;
  height: 100%;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}
.search_panel.active {
  display: flex !important;
}
@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.search_panel_content {
  width: 100%;
  height: 100%;
}
.search_input {
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 600;
  color: #929191;
}
.search_item:first-child {
  width: 30.453%;
}
.search_item:nth-child(2) {
  width: 13.961%;
}
.search_item:nth-child(3) {
  width: 13.961%;
}
.search_item:nth-child(4) {
  width: 9.947%;
}
.search_item:nth-child(5) {
  width: 9.947%;
}
.search_item div {
  font-size: 13px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.dropdown_item_select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  background-position: center right;
  background-repeat: no-repeat;
  border: solid 1px #ffffff;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.dropdown_item_select::-ms-expand {
  display: none;
}
.dropdown_item_select:hover {
  border: solid 1px #fa9e1c;
}
.dropdown_item_select:focus {
  outline-color: #fa9e1c;
}
.search_button {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 46px;
  padding-right: 46px;
  background: #31124b;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  border: none;
  outline: none;
  cursor: pointer;
}
.search_button:hover {
  opacity: 0.8;
}



.section_title {
  font-size: 30px;
  font-weight: 700;
  color: #2d2c2c;
  margin-top: -8px;
  text-transform: uppercase;
}


.intro {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 105px;
}
.intro_title {
  font-weight: 700;
  color: #2d2c2c;
  margin-top: -14px;
}
.intro_text {
  margin-top: 21px;
}
.intro_text p {
  font-size: 24px;
  font-style: italic;
  color: #929191;
  line-height: 1.336;
  margin-bottom: 0px;
}
.intro_items {
  margin-top: 69px;
}
.intro_item {
  width: 100%;
  height: 425px;
}
.intro_item_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(49, 18, 75, 0.55);
}
.intro_item_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}
.intro_item_content {
  width: 100%;
  height: 100%;
  z-index: 3;
}
.intro_date {
  position: absolute;
  top: 35px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  padding-left: 39px;
  padding-right: 39px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
  background: #fa9e1c;
  border-radius: 14px;
}
.intro_button {
  position: absolute;
  bottom: 51px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_center h1 {
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -0.05em;
}
.intro_price {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: -4px;
}
.rating {
  margin-top: 10px;
}
.rating i {
  font-size: 19px;
  margin-right: 4px;
}
.rating i:last-child {
  margin-right: 0px;
}
.rating_1 i:first-child {
  color: #ffeb8d;
}
.rating_2 i:first-child {
  color: #ffeb8d;
}
.rating_2 i:nth-child(2) {
  color: #fed46b;
}
.rating_3 i:first-child {
  color: #ffeb8d;
}
.rating_3 i:nth-child(2) {
  color: #fed46b;
}
.rating_3 i:nth-child(3) {
  color: #fbb53d;
}
.rating_4 i:first-child {
  color: #ffeb8d;
}
.rating_4 i:nth-child(2) {
  color: #fed46b;
}
.rating_4 i:nth-child(3) {
  color: #fbb53d;
}
.rating_4 i:nth-child(4) {
  color: #fa9e1b;
}
.rating_5 i:first-child {
  color: #ffeb8d;
}
.rating_5 i:nth-child(2) {
  color: #fed46b;
}
.rating_5 i:nth-child(3) {
  color: #fbb53d;
}
.rating_5 i:nth-child(4) {
  color: #fa9e1b;
}
.rating_5 i:nth-child(5) {
  color: #ef910a;
}



.cta {
  padding-top: 125px;
  padding-bottom: 116px;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
}
.cta_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.27;
}


.cta_item {
  padding: 40px 50px;
  background: #ffffff87;
}
.cta_title {
  font-size: 30px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
}
.cta_text {
  font-weight: 600;
  line-height: 2.29;
  margin-top: 14px;
  margin-bottom: 0px;
}
.cta_button {
  margin-top: 38px;
}
.rating_r i::before {
  font-family: "FontAwesome";
  content: "\f006";
  font-style: normal;
  font-size: 19px;
  margin-right: 4px;
}
.rating_r_1 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_2 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_2 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_3 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_3 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_3 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_4 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_4 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_4 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_4 i:nth-child(4)::before {
  color: #fa9e1b;
  content: "\f005";
}
.rating_r_5 i:first-child::before {
  color: #ffeb8d;
  content: "\f005";
}
.rating_r_5 i:nth-child(2)::before {
  color: #fed46b;
  content: "\f005";
}
.rating_r_5 i:nth-child(3)::before {
  color: #fbb53d;
  content: "\f005";
}
.rating_r_5 i:nth-child(4)::before {
  color: #fa9e1b;
  content: "\f005";
}
.rating_r_5 i:nth-child(5)::before {
  color: #ef910a;
  content: "\f005";
}
.cta_slider_nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
.cta_slider_prev {
  left: -75px;
}
.cta_slider_next {
  right: -75px;
}
.cta_slider_prev:hover .nav_path,
.cta_slider_prev:hover .nav_arrow {
  fill: url(#cta_grad_prev);
}
.cta_slider_next:hover .nav_path,
.cta_slider_next:hover .nav_arrow {
  fill: url(#cta_grad_next);
}



.offers {
  width: 100%;
  padding-top: 105px;
  padding-bottom: 43px;
  background: #f3f6f9;
}
.offers_items {
  margin-top: 90px;
}
.offers_col {
  margin-bottom: 83px;
}
.offers_item {
}
.offers_image_container {
  width: 100%;
  height: 100%;
}
.offers_image_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.offer_name {
  position: absolute;
  left: 0;
  bottom: 27px;
  width: 180px;
  height: 33px;
  background: #fa9e1b;
  overflow: hidden;
}
.offer_name a {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 35px;
  padding-left: 22px;
}
.offers_content {
}
.offers_price {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  color: #eda84a;
  line-height: 25px;
}
.offers_price span {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  margin-left: 12px;
}
.offers_rating {
}
.offers_rating i::before {
  font-size: 13px;
  margin-right: 2px;
}
.offers_text {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  margin-top: 16px;
  width: 230px;
  text-align: justify;
}
.offers_icons {
  margin-top: 17px;
}
.offers_icons_item {
  display: inline-block;
  margin-right: 15px;
}
.offers_icons_item:last-child {
  margin-right: 0px;
}
.offers_link {
  margin-top: 54px;
}
.offers_link a {
  font-size: 12px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.offers_link a:hover {
  color: #fa9e1b;
}


.testimonials {
  width: 100%;
  padding-top: 105px;
  padding-bottom: 105px;
  background: #ffffff;
}
.test_border {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
}



.test_slider_container {
  width: 100%;
  margin-top: 54px;
}
.test_item {
  padding-top: 36px;
}
.test_icon {
  position: absolute;
  top: 0px;
  left: 41px;
  width: 72px;
  height: 72px;
  background: #fa9e1b;
  border-radius: 50%;
  overflow: hidden;
}
.test_icon img {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto !important;
}
.test_content_container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 44.1%;
  background: rgba(49, 18, 75, 0.8);
}
.test_content {
  width: 100%;
  height: 100%;
  padding-left: 38px;
  padding-top: 44px;
  padding-right: 40px;
}
.test_item_info {
  position: absolute;
  left: 41px;
  bottom: calc(100% - 18px);
  background: #ffffff;
  padding-left: 20px;
  padding-right: 21px;
  padding-top: 9px;
  padding-bottom: 10px;
  z-index: 10;
}
.test_name {
  font-size: 14px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
}
.test_date {
  font-size: 11px;
  margin-top: 2px;
  color: #2d2c2c;
  padding-left: 2px;
}
.test_quote_title {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  padding-left: 3px;
}
.test_quote_text {
  font-weight: 500;
  color: #ffffff;
  margin-top: 11px;
}
.test_slider_nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
.test_slider_nav .nav_path,
.test_slider_nav .nav_arrow {
  fill: #c4ced2;
}
.test_slider_prev {
  left: -75px;
}
.test_slider_next {
  right: -75px;
}
.test_slider_prev:hover .nav_path,
.test_slider_prev:hover .nav_arrow {
  fill: url(#test_grad_prev);
}
.test_slider_next:hover .nav_path,
.test_slider_next:hover .nav_arrow {
  fill: url(#test_grad_next);
}



.trending {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 50px;
}
.trending_container {
  margin-top: 90px;
}
.trending_item {
  margin-bottom: 55px;
}
.trending_image {
  width: 82px;
  height: 76px;
  float: left;
}
.trending_image img {
  width: 100%;
}
.trending_content {
  padding-left: 94px;
}
.trending_title {
  margin-top: -5px;
}
.trending_title a {
  font-size: 16px;
  font-weight: 700;
  color: #2d2c2c;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.trending_title a:hover {
  color: #fa9e1b;
}
.trending_price {
  font-size: 14px;
  font-weight: 700;
  color: #fa9e1b;
  margin-top: 2px;
}
.trending_location {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  text-transform: uppercase;
  margin-top: 5px;
}



.home_contact {
  width: 100%;
  padding-bottom: 105px;
}
.home_contact_background {
  position: absolute;
  bottom: -34px;
  left: 0;
  width: 100%;
  height: 104%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.contact_title {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}
.contact_form_container {
  padding-top: 74px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 88px;
  background: linear-gradient(to top right, #fa9e1b, #8d4fff);
}
.contact_form {
  margin-top: 52px;
  font-size: 0px;
}
.input_field {
  height: 48px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
  background: transparent;
  color: #ffffff;
  border-bottom: solid 2px #e1e1e1;
}
.contact_form_name {
  width: calc((100% - 30px) / 2);
  margin-right: 30px;
}
.contact_form_email {
  width: calc((100% - 30px) / 2);
}
.contact_form_subject {
  width: 100%;
  margin-top: 11px;
}
.contact_form_message {
  height: 126px;
  width: 100%;
  border: none;
  outline: none;
  margin-top: 19px;
  background: transparent;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  border-bottom: solid 2px #e1e1e1;
  padding-top: 11px;
}
.form_submit_button {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 29px;
  padding-right: 29px;
  background: #31124b;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  border: none;
  outline: none;
  margin-top: 37px;
  cursor: pointer;
}
.form_submit_button:hover {
  opacity: 0.8;
}
.input_field::-webkit-input-placeholder,
.text_field::-webkit-input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field:-moz-placeholder,
.text_field:-moz-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field::-moz-placeholder,
.text_field::-moz-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field:-ms-input-placeholder,
.text_field:-ms-input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}
.input_field::input-placeholder,
.text_field::input-placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}


.footer {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 30px;
  background: #31124b;
}
.footer_title {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.footer_about {
  padding-top: 67px;
}
.footer_logo {
  position: absolute;
  left: 0;
  top: -24px;
}
.footer_about_text {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.footer_social_item {
  display: inline-block;
  width: 31px;
  height: 31px;
  border: solid 1px #fa9e1b;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  margin-right: 9px;
}
.footer_social_item:last-child {
  margin-right: 0px;
}
.footer_social_item a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.footer_social_item a i {
  display: block;
  position: relative;
  color: #ffffff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 12px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.footer_social_item:hover {
  background: #fa9e1b;
}
.footer_social_item:hover a i {
  color: #31124b;
}


.footer_blog {
  margin-top: 39px;
}
.footer_blog_item {
  margin-bottom: 21px;
}
.footer_blog_item:last-child {
  margin-bottom: 0px;
}
.footer_blog_image {
  width: 60px;
  height: 60px;
  float: left;
}
.footer_blog_image img {
  width: 100%;
}
.footer_blog_content {
  padding-left: 81px;
}
.footer_blog_title {
  margin-top: -4px;
  padding-left: 1px;
}
.footer_blog_title a {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.footer_blog_title a:hover {
  color: #fa9e1b;
}
.footer_blog_date {
  font-size: 12px;
  font-weight: 400;
  color: #fa9e1b;
  margin-top: 7px;
}


.footer_tags {
  margin-top: 40px;
}
.tag_item {
  height: 35px;
  float: left;
  margin-right: 9px;
  margin-bottom: 9px;
  border: solid 1px #fa9e1b;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.tag_item:hover {
  background: #fa9e1b;
}
.tag_item a {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
  padding-left: 25px;
  padding-right: 25px;
}

.contact_info_list {
  margin-top: 40px;
}
.contact_info_item {
  margin-bottom: 22px;
}
.contact_info_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.contact_info_icon img {
  display: block;
  width: 100%;
}
.contact_info_text,
.contact_info_text a {
  color: #ffffff;
  line-height: 2.14;
  margin-top: -4px;
}
.contact_info_item:hover .contact_info_text,
.contact_info_item:hover .contact_info_text a {
  color: #fa9e1b;
}

.copyright {
  background: #1f0733;
}
.copyright_content {
  height: 52px;
}
.copyright_content div {
  font-size: 12px;
  font-weight: 600;
  color: #685677;
}
.footer_nav_container {
  height: 52px;
}
.footer_nav {
}
.footer_nav_list {
  font-size: 0px;
}
.footer_nav_item {
  display: inline-block;
  margin-right: 40px;
}
.footer_nav_item a {
  font-size: 12px;
  font-weight: 600;
  color: #685677;
  text-transform: uppercase;
  line-height: 2;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.footer_nav_item a:hover {
  color: #ffffff;
}
