.header {
  width: 100%;
  background: rgba(54, 19, 84, 0.6);
}
.header.scrolled {
  background: rgba(54, 19, 84, 0.85);
}

.top_bar {
  width: 100%;
  height: 36px;
  background: #350a4e;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.header.scrolled .top_bar {
  visibility: hidden;
  opacity: 0;
  height: 0px;
}
.phone {
  display: inline-block;
  margin-right: 36px;
  line-height: 36px;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
}
.social_list_item {
  position: relative;
  display: inline-block;
  margin-right: 3px;
}
.social_list_item:last-child {
  margin-right: 0px;
}
.social_list_item a {
  display: block;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 36px;
}
.social_list_item:hover a i {
  color: #fa9e1c;
}
.social_list_item a i {
  font-size: 12px;
  color: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.social_list_item a:hover {
  color: #ffffff;
}
.user_box_link {
  display: inline-block;
}
.user_box_link a {
  display: block;
  font-size: 10px;
  line-height: 36px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}
.user_box_link a:hover {
  color: #fa9e1c;
}
.user_box_login {
  margin-right: 15px;
}
.user_box_login::after {
  display: block;
  position: absolute;
  top: 15px;
  right: -9px;
  background: #ffffff;
  width: 1px;
  height: 9px;
  content: "";
}
.main_nav_col {
  height: 143px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}
.header.scrolled .main_nav_col {
  height: 100px;
}

.logo_container {
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  transform: translateY(-6px);
}
.header.scrolled .logo_container {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.logo a {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  color: #f4f4f8;
  font-weight: 800;
  text-transform: uppercase;
}
.logo a img {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 9px;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
  transform: translateY(2px);
  width: 99px;
  /* height: 110px;
  margin-top: 20px; */
}

.main_nav_container {
  margin-right: 221px;
}
.main_nav_item {
  position: relative;
  display: inline-block;
  margin-right: 40px;
}
.main_nav_item:last-child {
  margin-right: 0px;
}
.main_nav_item a {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-top: 10px;
}
.main_nav_item::after {
  display: block;
  position: absolute;
  left: -1px;
  bottom: -11px;
  width: calc(100% + 2px);
  height: 2px;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
  content: "";
  opacity: 0;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.main_nav_item:hover::after {
  opacity: 1;
}
.content_search {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
  cursor: pointer;
}
.header.scrolled .content_search {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.mag_glass {
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.content_search img {
  width: 17px;
  height: 17px;
}
.content_search:hover .mag_glass {
  fill: #fa9e1c;
}
.content_search:hover .mag_glass {
  fill: #fa9e1c;
}
.search_form {
  position: absolute;
  right: 15px;
  top: 120px;
  width: 237px;
  height: 40px;
  visibility: hidden;
  opacity: 0;
}
.search_form.active {
  top: 105px;
  visibility: visible;
  opacity: 1;
}
.search_content_input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #1b0b32;
  padding-left: 20px;
  outline: none !important;
  border: none !important;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}
.bez_1 {
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}

.hamburger {
  display: none;
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
  cursor: pointer;
  margin-left: 20px;
}
.header.scrolled .hamburger {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.hamburger i {
  font-size: 24px;
  color: #ffffff;
}
.hamburger:hover i {
  color: #fa9e1c;
}
.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #fa9e1b, #8d4fff);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
}
.menu.active {
  opacity: 0.95;
  visibility: visible;
}
.menu_content {
  width: 100%;
  height: 100%;
}
.menu_item {
  position: relative;
  margin-bottom: 3px;
}
.menu_item:last-child {
  margin-bottom: 0px;
}
.menu_logo {
  margin-bottom: 28px;
}
.menu_logo a img {
  width: 40px !important;
}
.menu_item a {
  display: inline-block;
  position: relative;
  font-family: "Beyond";
  font-size: 36px;
  color: #ffffff;
  font-weight: 400;
}
.menu_item a::after {
  display: block;
  position: absolute;
  top: 60%;
  left: 0;
  width: 0;
  height: 4px;
  background: #ffffff;
  letter-spacing: 2px;
  content: "";
  z-index: 10;
  pointer-events: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 400ms ease;
}
.menu_item:hover a::after {
  width: 100%;
}
.menu_close_container {
  position: absolute;
  top: 94px;
  right: 122px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu_close {
  top: 9px;
  width: 21px;
  height: 3px;
  background: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.menu_close::after {
  display: block;
  position: absolute;
  top: -9px;
  left: 9px;
  content: "";
  width: 3px;
  height: 21px;
  background: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.menu_close_container:hover .menu_close,
.menu_close_container:hover .menu_close::after {
  background: #fa9e1c;
}

